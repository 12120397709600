import {
  SET_FACEBOOK_DATA,
  SET_FACEBOOK_DATA_PREVIOUS,
  SET_FACEBOOK_DATA_NULL,
  SET_FACEBOOK_DATA_PREVIOUS_NULL,
} from "../constants/FacebookData";

const initState = {
  data: {
    roas: 0,
    spend: 0,
    impressions: 0,
    graphData: [],
  },
  dataPrevious: {
    roas: 0,
    spend: 0,
    impressions: 0,
    graphData: [],
  },
};

const facebookData = (state = initState, action) => {
  switch (action.type) {
    case SET_FACEBOOK_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_FACEBOOK_DATA_PREVIOUS:
      return {
        ...state,
        dataPrevious: action.payload,
      };
    case SET_FACEBOOK_DATA_NULL:
      return {
        ...state,
        data: {
          roas: 0,
          spend: 0,
          impressions: 0,
          graphData: [],
        },
      };
    case SET_FACEBOOK_DATA_PREVIOUS_NULL:
      return {
        ...state,
        dataPrevious: {
          roas: 0,
          spend: 0,
          impressions: 0,
          graphData: [],
        },
      };
    default:
      return state;
  }
};

export default facebookData;
