import { useEffect } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { connect } from "react-redux";
import { Avatar, Dropdown, Menu } from "antd";
import {
  EditOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import ResetState from "utils/reset-state";

export const NavProfile = ({ totalMerchants }) => {
  const { logout, user } = useKindeAuth();
  let menuItem = [
    {
      title: "Edit Profile",
      icon: EditOutlined,
      path: "/app/pages/setting/profile",
    },
    {
      title: "FAQ",
      icon: QuestionCircleOutlined,
      path: "https://www.dailymentor.co/app/faq/",
    },
  ];
  useEffect(() => {}, [totalMerchants.length]);

  const handleLogOut = () => {
    logout();
    ResetState();
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={user?.picture} />
          <div className="pl-3">
            <h4 className="mb-0">{user?.given_name}</h4>
            <span className="text-muted">{user?.email}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a
                  href={el.path}
                  target={el.title.includes("FAQ") ? "_blank" : ""}
                  rel="noreferrer"
                >
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={handleLogOut}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar
            src={user?.picture}
            style={{
              backgroundColor: "grey",
            }}
          />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth, merchants }) => {
  const { user } = auth;
  const { merchant: totalMerchants } = merchants;
  return { user, totalMerchants };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile);
