import {
  AreaChartOutlined,
  DashboardOutlined,
  FacebookOutlined,
  FileSearchOutlined,
  GoogleOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  ProductOutlined,
  SlidersOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-sales",
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        title: "Sales Overview",
        icon: DashboardOutlined,
        breadcrumb: false,
        enabled: true,
        submenu: [],
      },
      // TODO :: Remove this comment in future
      // {
      //   key: 'dashboards-marketingDashboard',
      //   path: `${APP_PREFIX_PATH}/dashboards/marketingDashboard`,
      //   title: 'Marketing Overview',
      //   icon: DotChartOutlined,
      //   breadcrumb: false,
      //   submenu: []
      // },
      {
        key: "dashboards-facebookDashboard",
        path: `${APP_PREFIX_PATH}/dashboards/facebookDashboard`,
        title: "Facebook Overview",
        icon: FacebookOutlined,
        breadcrumb: false,
        enabled: false,
        submenu: [],
      },
      {
        key: "dashboards-googleDashboard",
        path: `${APP_PREFIX_PATH}/dashboards/googleDashboard`,
        title: "Google Overview",
        icon: GoogleOutlined,
        breadcrumb: false,
        enabled: false,
        submenu: [],
      },
      {
        key: "dashboards-topProducts",
        path: `${APP_PREFIX_PATH}/dashboards/topProducts`,
        title: "Top Products",
        icon: VerticalAlignTopOutlined,
        breadcrumb: false,
        enabled: false,
        submenu: [],
      },
      {
        key: "dashboards-targets",
        path: `${APP_PREFIX_PATH}/dashboards/targets`,
        title: "Targets",
        icon: AreaChartOutlined,
        breadcrumb: false,
        enabled: false,
        submenu: [],
      },
      {
        key: "dashboards-merPlayground",
        path: `${APP_PREFIX_PATH}/dashboards/merPlayground`,
        title: "MER Playground",
        icon: SlidersOutlined,
        breadcrumb: false,
        enabled: false,
        submenu: [],
      },
      {
        key: "costs-and-expenses",
        title: "Costs and Expenses",
        icon: FileSearchOutlined,
        breadcrumb: false,
        enabled: true,
        submenu: [
          {
            key: "costs-variants",
            path: `${APP_PREFIX_PATH}/dashboards/costsAndExpenses/variants`,
            title: "Products & Variants",
            icon: ProductOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "dashboards-merchant",
        title: "Store Details",
        icon: SettingOutlined,
        breadcrumb: false,
        enabled: true,
        submenu: [
          {
            key: "dashboards-merchant-storeConnections",
            path: `${APP_PREFIX_PATH}/dashboards/merchant/storeConnections`,
            title: "Store Connections",
            icon: ShopOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-merchant-addAccount",
            path: `${APP_PREFIX_PATH}/dashboards/merchant/addAccount`,
            title: "Add Account",
            icon: PlusCircleOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
