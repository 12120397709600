import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { notification } from "antd";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// API Request interceptor
service.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
      duration: 3,
    });
    return Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: "",
      duration: 3,
    };
    if (error) {
      notificationParam.message = "Api Failure";
    }
    notification.error(notificationParam);
    return Promise.reject(error);
  }
);

export default service;
