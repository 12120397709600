import {
	AUTH_TOKEN,
	AUTHENTICATED,
	HIDE_AUTH_MESSAGE,
	SHOW_AUTH_MESSAGE,
	SHOW_LOADING,
	SIGNIN,
	SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS
} from '../constants/Auth';

const initState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    user: {},
    token: localStorage.getItem(AUTH_TOKEN),
}

const auth = (state = initState, action) => {
    switch (action.type) {
        case SIGNIN:
            return {
                ...state,
                loading: false,
                user: action.payload
            }
        case AUTHENTICATED:
            return {
                ...state,
                loading: false,
                redirect: '/',
                token: action.token,
            }
        case SHOW_AUTH_MESSAGE:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false
            }
        case HIDE_AUTH_MESSAGE:
            return {
                ...state,
                message: '',
                showMessage: false,
            }
        case SIGNOUT_SUCCESS: {
            return {
                ...state,
                token: null,
                user: {},
                redirect: '/auth/login',
                loading: false,
            }
        }
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                token: null,
                redirect: '/auth/login',
                loading: false,
            }
        }
        case SHOW_LOADING: {
            return {
                ...state,
                loading: action.payload
            }
        }
        case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
            return {
                ...state,
                loading: false,
                token: action.token
            }
        }
        case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
            return {
                ...state,
                loading: false,
                token: action.token
            }
        }
        default:
            return state;
    }
}

export default auth
