import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

interface ApiContextType {
  get: <T>(url: string, config?: AxiosRequestConfig) => Promise<T>;
  post: <T>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;
  put: <T>(url: string, data?: any, config?: AxiosRequestConfig) => Promise<T>;
  delete: <T>(url: string, config?: AxiosRequestConfig) => Promise<T>;
  getToken: (() => Promise<string | undefined>) | undefined;
  loading: boolean;
}
const ApiContext = createContext<ApiContextType | undefined>(undefined);

export const ApiProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [apiLoading, setApiLoading] = useState(false);
  const { getToken, isLoading: isAuthLoading } = useKindeAuth();

  const api = axios.create({
    baseURL: API_BASE_URL,
  });

  api.interceptors.request.use(async (config) => {
    if (!isAuthLoading) {
      const token = getToken ? await getToken() : undefined;
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      console.error("API Error:", error);
      return Promise.reject(error);
    }
  );

  const makeRequest = useCallback(
    async <T,>(
      method: "get" | "post" | "put" | "delete",
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T> => {
      setApiLoading(true);
      try {
        const response: AxiosResponse<T> = await api[method](url, data, config);
        return response.data;
      } finally {
        setApiLoading(false);
      }
    },
    [api]
  );

  const get = useCallback(
    <T,>(url: string, config?: AxiosRequestConfig) =>
      makeRequest<T>("get", url, undefined, config),
    [makeRequest]
  );

  const post = useCallback(
    <T,>(url: string, data?: any, config?: AxiosRequestConfig) =>
      makeRequest<T>("post", url, data, config),
    [makeRequest]
  );

  const put = useCallback(
    <T,>(url: string, data?: any, config?: AxiosRequestConfig) =>
      makeRequest<T>("put", url, data, config),
    [makeRequest]
  );

  const deleteRequest = useCallback(
    <T,>(url: string, config?: AxiosRequestConfig) =>
      makeRequest<T>("delete", url, undefined, config),
    [makeRequest]
  );

  const loading = apiLoading || isAuthLoading;

  const value: ApiContextType = {
    get,
    post,
    put,
    delete: deleteRequest,
    getToken,
    loading,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = (): ApiContextType => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
};
