import {
  SET_MERCHANTS,
  SELECTED_MERCHANT,
  SET_MERCHANT,
  SET_TARGETS,
  SET_TARGETS_NULL,
} from "../constants/Merchant";

export const setMerchants = (merchant) => {
  return {
    type: SET_MERCHANTS,
    payload: merchant,
  };
};

export const selectedMerchant = (selMerchant) => {
  return {
    type: SELECTED_MERCHANT,
    payload: selMerchant,
  };
};

export const setMerchant = () => {
  return {
    type: SET_MERCHANT,
  };
};

export const setTargets = (payload) => {
  return {
    type: SET_TARGETS,
    payload,
  };
};

export const setTargetsNull = () => {
  return {
    type: SET_TARGETS_NULL,
  };
};
