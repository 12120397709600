import axios from "../interceptors";

const TargetsService = {
  getTargets: async (token, merchantId, year, month = "") => {
    let query = `?merchantId=${merchantId}&year=${year}`;
    if (month) {
      query += `&month=${month}`;
    }
    return axios.get("targets" + query, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  setTargets: async (data, token) =>
    axios.post("targets", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default TargetsService;
