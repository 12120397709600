import {
  SET_FACEBOOK_DATA,
  SET_FACEBOOK_DATA_NULL,
  SET_FACEBOOK_DATA_PREVIOUS,
  SET_FACEBOOK_DATA_PREVIOUS_NULL,
} from "../constants/FacebookData";

export const setFacebookData = (data) => {
  return {
    type: SET_FACEBOOK_DATA,
    payload: data,
  };
};

export const setFacebookDataPrevious = (data) => {
  return {
    type: SET_FACEBOOK_DATA_PREVIOUS,
    payload: data,
  };
};

export const setFacebookDataNull = () => {
  return {
    type: SET_FACEBOOK_DATA_NULL,
  };
};

export const setFacebookDataPreviousNull = () => {
  return {
    type: SET_FACEBOOK_DATA_PREVIOUS_NULL,
  };
};
