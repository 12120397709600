import {
  FILTER_SHOPIFY_ORDERS_BY_DATE,
  SET_SHOPIFY_ORDERS_BY_DATE,
} from "../constants/ShopifyOrders";

export const setShopifyOrdersByDate = (payload) => {
  return {
    type: FILTER_SHOPIFY_ORDERS_BY_DATE,
    payload: payload,
  };
};

export const setShopifyOrders = () => {
  return {
    type: SET_SHOPIFY_ORDERS_BY_DATE,
  };
};
