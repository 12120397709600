import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import { PersistGate } from "redux-persist/integration/react";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import { ApiProvider } from "hooks/useAPI";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <KindeProvider
        isDangerouslyUseLocalStorage={process.env.NODE_ENV === "development"}
        clientId="dd77fa70e0b7442cabc1ed8cc02c947e"
        domain="https://dailymentor.kinde.com"
        logoutUri={window.location.origin}
        redirectUri={`${window.location.origin}/auth/callback`}
      >
        <Provider store={store}>
          <ApiProvider>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeSwitcherProvider
                themeMap={themes}
                defaultTheme={THEME_CONFIG.currentTheme}
                insertionPoint="styles-insertion-point"
              >
                <Router>
                  <Switch>
                    <Route path="/" component={Views} />
                  </Switch>
                </Router>
              </ThemeSwitcherProvider>
            </PersistGate>
          </ApiProvider>
        </Provider>
      </KindeProvider>
    </div>
  );
}

export default App;
