import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  const routes = [
    {
      path: `${AUTH_PREFIX_PATH}/login`,
      component: lazy(() => import(`./authentication/login`)),
    },
    {
      path: `${AUTH_PREFIX_PATH}/register`,
      component: lazy(() => import(`./authentication/register`)),
    },
    {
      path: `${AUTH_PREFIX_PATH}/forgot-password`,
      component: lazy(() => import(`./authentication/forgot-password`)),
    },
    {
      path: `${AUTH_PREFIX_PATH}/profile`,
      component: lazy(() => import(`./authentication/edit-profile`)),
    },
    {
      path: `${AUTH_PREFIX_PATH}/callback`,
      component: lazy(() => import(`./authentication/callback`)),
    },
    {
      path: `${AUTH_PREFIX_PATH}/*`,
      component: lazy(() => import(`./errors/error-page-1`)),
    },
    {
      path: `${AUTH_PREFIX_PATH}/callback`,
      component: lazy(() => import(`./errors/error-page-1`)),
    },
  ];
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route key={path} path={path} component={component} />
        ))}
        <Redirect
          from={`${AUTH_PREFIX_PATH}`}
          to={`${AUTH_PREFIX_PATH}/login`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
