export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <span className="font-weight-semibold"> Daily Mentor </span> All rights
        reserved.
      </span>
    </footer>
  );
}
