import axios from "../interceptors";

const DataService = {};

DataService.getData = async (token, merchantId, start, end, channels = []) => {
  let url = `data?merchantId=${merchantId}&start=${start}`; // writing it like this for code readability
  if (end) {
    url += `&end=${end}`;
  }
  if (channels.length) {
    url += `&channels=${channels.join(",")}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default DataService;
