import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  const routes = [
    {
      path: `${APP_PREFIX_PATH}/dashboards`,
      component: lazy(() => import(`./dashboards`)),
    },
    {
      path: `${APP_PREFIX_PATH}/pages`,
      component: lazy(() => import(`./pages`)),
    },
    {
      path: `${APP_PREFIX_PATH}/*`,
      component: lazy(() => import(`views/auth-views/errors/error-page-1`)),
    },
  ];
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route path={path} key={path} component={component} />
        ))}
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboards`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
