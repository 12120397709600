import {
    ACTIVESUBSCRIPTION,
    SETACTIVESUBSCRIPTIONNULL,
    SETTOTALSUBSCRIPTIONSNULL,
    TOTALSUBSCRIPTIONS
} from "redux/constants/Subscription";

const initState = {
    activeSubscription: {},
    totalSubscriptions: [],
}

const {activeSubscription, totalSubscriptions} = initState;

const subscriptionDetails = (state = initState, action) => {
    switch (action.type) {
        case ACTIVESUBSCRIPTION:
            return {
                ...state,
                activeSubscription: action.payload
            }
        case TOTALSUBSCRIPTIONS:
            return {
                ...state,
                totalSubscriptions: action.payload
            }
        case SETACTIVESUBSCRIPTIONNULL:
            return {
                ...state,
                activeSubscription
            }
        case SETTOTALSUBSCRIPTIONSNULL:
            return {
                ...state,
                totalSubscriptions
            }
        default:
            return state;
    }
}

export default subscriptionDetails;
