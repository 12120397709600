import {
	SET,
    RESET
} from '../constants/InvitedUser';

const initState = {
  user: []
}

const inviteUser = (state = initState, action) => {
	switch (action.type) {
		case SET:
			return {
				...state,
				user: action.payload
			}
		case RESET:
			return {
				...state,
				user: []
			}
		default:
			return state;
	}
}

export default inviteUser;