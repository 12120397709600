import moment from "moment-timezone";

// Dates are selected in the users timezone.
// This adjusts it to the shops timezone and sets it to the start and end of the day.
// The API handles the timezone conversion.
export const getLocalTime = (startDate: Date, endDate: Date) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const adjustedStartDate = moment.tz(startDate, userTimezone).startOf("day");
  const adjustedEndDate = moment.tz(endDate, userTimezone).endOf("day");

  const startDateString = `${adjustedStartDate.format("YYYY-MM-DD")}`;
  const endDateString = `${adjustedEndDate.format("YYYY-MM-DD")}`;

  return { startDateString, endDateString };
};
