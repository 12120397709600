import {
    SET,
    RESET
  } from '../constants/InvitedUser';
  
  export const set = (user) => {
    return {
      type: SET,
      payload: user
    }
  };
  
  export const reset = (token) => {
    return {
      type: RESET
    }
  };
  