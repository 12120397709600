import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { Merchant } from "types/merchant";
import { useApi } from "hooks/useAPI";
import { useEffect } from "react";
import {
  setMerchants,
  selectedMerchant as setSelectedMerchant,
} from "redux/actions";

const MerchantSelector = () => {
  const dispatch = useDispatch();
  const { get, loading } = useApi();
  const merchants = useSelector((state: any) => state.merchants.merchant);
  const selectedMer = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );

  useEffect(() => {
    const fetchMerchants = async () => {
      const data = await get<Merchant[]>("merchant/getUserMerchants");
      dispatch(setMerchants(data));
      if (data.length > 0 && !selectedMer.id) {
        dispatch(setSelectedMerchant(data[0]));
      }
    };

    if (!loading) {
      fetchMerchants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeSelect = async (id: string) => {
    const selectedMerchant = merchants.find(
      (merchant: Merchant) => merchant.id === id
    );
    if (selectedMerchant) {
      await dispatch(setSelectedMerchant(selectedMerchant));
    }
  };

  if (merchants.length === 0) {
    return null;
  }

  return (
    <>
      <Select
        showArrow={false}
        style={{ marginTop: "20px", width: "150px", left: "-30px" }}
        placeholder="Current Account"
        value={selectedMer.id}
        onChange={onChangeSelect}
      >
        {merchants.map((item: Merchant) => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default MerchantSelector;
