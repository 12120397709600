import {
  SET_COMMON_PROPS,
  PAGE_COMMON_PROPS,
  SET_COMMON_PROPS_NULL,
  SET_CHANNELS,
  SET_COMMON_DATES,
} from "../constants/Common";

export const setCommonProps = (payload) => {
  return {
    type: SET_COMMON_PROPS,
    payload: payload,
  };
};

export const setCommonDates = (payload) => {
  return {
    type: SET_COMMON_DATES,
    payload: payload,
  };
};

export const setChannels = (payload) => {
  return {
    type: SET_CHANNELS,
    payload,
  };
};

export const pageCommonProps = (payload) => {
  return {
    type: PAGE_COMMON_PROPS,
    payload: payload,
  };
};

export const setCommonPropsNull = () => {
  return {
    type: SET_COMMON_PROPS_NULL,
  };
};
