import {
  SET_MERCHANTS,
  SELECTED_MERCHANT,
  SET_MERCHANT,
  SET_TARGETS,
  SET_TARGETS_NULL,
} from "../constants/Merchant";

const initState = {
  merchant: [],
  selectedMerchant: {},
  targets: [],
};

const merchants = (state = initState, action) => {
  switch (action.type) {
    case SET_MERCHANTS:
      return {
        ...state,
        merchant: action.payload,
      };
    case SELECTED_MERCHANT:
      return {
        ...state,
        selectedMerchant: action.payload,
      };
    case SET_MERCHANT:
      return {
        ...state,
        merchant: [],
        selectedMerchant: {},
      };
    case SET_TARGETS:
      return {
        ...state,
        targets: action.payload,
      };
    case SET_TARGETS_NULL:
      return {
        ...state,
        targets: [],
      };
    default:
      return state;
  }
};

export default merchants;
