import {store} from "../redux/store";

import {
    reset,
    setActiveSubscriptionNull,
    setCommonPropsNull,
    setFacebookDataNull,
    setGoogleDataNull,
    setMerchant,
    setShopifyOrders,
    setTargetsNull,
    setTotalSubscriptionsNull,
    signOut,
} from 'redux/actions';

const ResetState = () => {
    const states = [
        reset(),
        setActiveSubscriptionNull(),
        setCommonPropsNull(),
        setFacebookDataNull(),
        setGoogleDataNull(),
        setMerchant(),
        setShopifyOrders(),
        setTotalSubscriptionsNull(),
        setTargetsNull(),
        signOut()
    ];
    states.forEach(state => store.dispatch(state));
}


export default ResetState;