import {combineReducers} from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Merchant from './Merchant';
import ShopifyOrders from './ShopifyOrders';
import FacebookData from './FacebookData';
import Common from './Common';
import InvitedUser from './InvitedUser';
import Subscription from './Subscription';
import GoogleData from './GoogleData'

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    merchants: Merchant,
    shopifyOrders: ShopifyOrders,
    facebookData: FacebookData,
    common: Common,
    invitedUser: InvitedUser,
    subscription: Subscription,
    googleData: GoogleData
});

export default reducers;
