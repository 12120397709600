export const env = {
  API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT,
  FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
  GOOGLE_SCOPES: process.env.REACT_APP_GOOGLE_SCOPES,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  WHITELISTED_DOMAINS: process.env.REACT_APP_WHITELISTED_DOMAINS,
  SHOPIFY_APP_URL: process.env.REACT_APP_SHOPIFY_APP_URL,
  COOKIES_DOMAIN: process.env.REACT_APP_COOKIES_DOMAIN,
  REACT_APP_SUPER_ADMIN: process.env.REACT_APP_SUPER_ADMIN,
};
