import {
  SET_GOOGLE_DATA,
  SET_GOOGLE_DATA_NULL,
  SET_GOOGLE_DATA_PREVIOUS,
  SET_GOOGLE_DATA_PREVIOUS_NULL,
} from "../constants/GoogleData";

export const setGoogleData = (data) => {
  return {
    type: SET_GOOGLE_DATA,
    payload: data,
  };
};

export const setGoogleDataPrevious = (data) => {
  return {
    type: SET_GOOGLE_DATA_PREVIOUS,
    payload: data,
  };
};

export const setGoogleDataNull = (data) => {
  return {
    type: SET_GOOGLE_DATA_NULL,
  };
};

export const setGoogleDataPreviousNull = (data) => {
  return {
    type: SET_GOOGLE_DATA_PREVIOUS_NULL,
  };
};
