import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const handleRouting = (isAuthenticated, children, location) => {
  if (isAuthenticated) {
    return children;
  }
  localStorage.setItem("nextUrl", location.pathname + location.search);
  return (
    <Redirect
      to={{
        pathname: AUTH_PREFIX_PATH,
        state: { from: location },
      }}
    />
  );
};

function RouteInterceptor({ children, ...rest }) {
  const { isAuthenticated, isLoading } = useKindeAuth();

  if (isLoading) {
    return <div />;
  }

  if (!isLoading) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          handleRouting(isAuthenticated, children, location)
        }
      />
    );
  }
}

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  return (
    <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
      <Switch>
        <Route exact path="/">
          <Redirect to={APP_PREFIX_PATH} />
        </Route>
        <Route path={AUTH_PREFIX_PATH}>
          <AuthLayout direction={direction} />
        </Route>
        <RouteInterceptor path={APP_PREFIX_PATH}>
          <AppLayout direction={direction} location={location} />
        </RouteInterceptor>
      </Switch>
    </ConfigProvider>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale, direction } = theme;
  return { locale, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
