import {
  SET_GOOGLE_DATA,
  SET_GOOGLE_DATA_PREVIOUS,
  SET_GOOGLE_DATA_NULL,
  SET_GOOGLE_DATA_PREVIOUS_NULL,
} from "../constants/GoogleData";

const initState = {
  data: {},
};

const googleData = (state = initState, action) => {
  switch (action.type) {
    case SET_GOOGLE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_GOOGLE_DATA_PREVIOUS:
      return {
        ...state,
        data: action.payload,
      };
    case SET_GOOGLE_DATA_NULL:
      return {
        ...state,
        data: {},
      };
    case SET_GOOGLE_DATA_PREVIOUS_NULL:
      return {
        ...state,
        dataPrevious: {},
      };
    default:
      return state;
  }
};

export default googleData;
