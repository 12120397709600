import {ACTIVESUBSCRIPTION, TOTALSUBSCRIPTIONS} from "redux/constants/Subscription";

export const setActiveSubscription = (payload) => {
    return {
        type: ACTIVESUBSCRIPTION,
        payload: payload
    }
}

export const setTotalSubscriptions = (payload) => {
    return {
        type: TOTALSUBSCRIPTIONS,
        payload: payload
    }
}

export const setActiveSubscriptionNull = () => {
    return {
        type: ACTIVESUBSCRIPTION,
    }
}

export const setTotalSubscriptionsNull = () => {
    return {
        type: TOTALSUBSCRIPTIONS,
    }
}
